<!-- serial-edit -->
<template>
  <el-dialog
    class="serial-edit"
    :visible.sync="open"
    :title="title"
    width="560px"
    :close-on-click-modal="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <el-form
      class="serial-edit-form"
      ref="form"
      v-loading="loading"
      :model="model"
      :rules="rules"
      size="small"
      label-width="85px"
      @submit.native.prevent
    >
      <el-form-item label="主播名称:" prop="signId">
        <!-- 新增流水 -->
        <template v-if="!isEdit">
          <sign
            v-if="open"
            class="form-anchor"
            v-model="model.signId"
            :status="cooperationStatusEnumList"
            @on-change="handleFormSignChange"
          >
            <template v-slot="{ item }">
              <div class="anchor-item">
                <div class="item-container">
                  <span class="item-label">主播名称:</span>
                  <span class="item-value">{{ item.anchorName }}</span>
                </div>

                <div class="item-container">
                  <span class="item-label">游戏名称:</span>
                  <span class="item-value">{{ item.gameName }}</span>
                </div>

                <div class="item-container item-emphasis">
                  <span class="item-label">首播日期:</span>
                  <span class="item-value">{{ item.firstShowTime }}</span>
                </div>

                <div class="item-container item-emphasis">
                  <span class="item-label">运营名称:</span>
                  <span class="item-value">{{ item.operatorName || '-' }}</span>
                </div>

                <div class="item-container item-emphasis">
                  <span class="item-label">商务名称:</span>
                  <span class="item-value">{{ item.username }}</span>
                </div>
              </div>
            </template>
          </sign>
        </template>

        <!-- 编辑流水 -->
        <template v-else>
          <el-input :value="model.anchorName" disabled />
        </template>
      </el-form-item>

      <el-form-item label="流水日期:" prop="serialDate">
        <el-date-picker
          v-model="model.serialDate"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择"
          :picker-options="serialDatePickerOptions"
        />
      </el-form-item>

      <el-form-item label="流水类型:" prop="serialType">
        <serial-type v-model="model.serialType" />
      </el-form-item>

      <el-form-item label="游戏名称:" prop="gameId">
        <game-name v-model="model.gameId" />
      </el-form-item>

      <el-form-item label="流水数:" prop="income">
        <el-input
          v-model.number="model.income"
          placeholder="请输入"
          clearable
          @input="handleFormIncomeInput"
        >
          <template v-slot:append>元</template>
        </el-input>
      </el-form-item>

      <el-form-item label="注册数:" prop="registration">
        <el-input
          v-model.number="model.registration"
          placeholder="请输入"
          clearable
        >
          <template v-slot:append>个</template>
        </el-input>
      </el-form-item>

      <el-form-item
        v-if="sign && sign.payment !== paymentTypeEnum.cps"
        label="CPT支出:"
        prop="expenditureCPT"
      >
        <el-input
          v-model.number="model.expenditureCPT"
          placeholder="请输入"
          clearable
        >
          <template v-slot:append>元</template>
        </el-input>
      </el-form-item>

      <el-form-item
        v-if="sign && sign.payment !== paymentTypeEnum.cpt"
        label="CPS支出:"
      >
        <el-input
          :value="model.expenditureCPS"
          disabled
          placeholder="请输入"
          clearable
        >
          <template v-slot:append>元</template>
        </el-input>
      </el-form-item>
    </el-form>

    <template v-slot:footer>
      <div class="serial-edit-operate">
        <el-button size="small" @click="handleOperateCancelClick"
          >取消
        </el-button>

        <el-button
          type="primary"
          size="small"
          :loading="saving"
          @click="handleOperateOkClick"
          >确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import { mapState } from 'vuex';
  import Sign from '../../../../components/sign';
  import SerialType from '../../../../components/serial-type';
  import GameName from '../../../../components/game-name';
  import cooperationStatus from '../../../../enum/cooperation-status.js';
  import paymentType from '../../../../enum/payment-type.js';
  import serialTypes from '../../../../enum/serial-type.js';
  import { format } from '../../../../util/date.js';
  import { isNonNegativeIntString } from '../../../../util/validator.js';
  import {
    getSerial,
    addSerial,
    updateSerial,
  } from '../../../../api/anchor/serial';

  // 非负整数校验
  const nonNegativeValidator = (rule, value, callback) => {
    !isNonNegativeIntString(value)
      ? callback(new Error('请填写非负整数'))
      : callback();
  };

  export default {
    name: 'serial-edit',
    components: {
      Sign,
      SerialType,
      GameName,
    },
    props: {
      // 是否开启对话框
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        // 当前编辑的流水对象
        serial: null,
        model: null,
        rules: {
          signId: [{ required: true, message: '请选择', trigger: 'change' }],
          serialDate: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
          serialType: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
          gameId: [{ required: true, message: '请选择', trigger: 'change' }],
          income: [
            { required: true, message: '请填写', trigger: 'change' },
            { validator: nonNegativeValidator, trigger: 'change' },
          ],
          registration: [
            { required: true, message: '请填写', trigger: 'change' },
            { validator: nonNegativeValidator, trigger: 'change' },
          ],
          expenditureCPT: [
            { required: true, message: '请填写', trigger: 'change' },
            { validator: nonNegativeValidator, trigger: 'change' },
          ],
        },
        // 当前选择的签约对象
        sign: null,
        loading: false,
        saving: false,
      };
    },
    computed: {
      ...mapState('anchor/serial', ['serialId']),

      open: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      // 是否是编辑
      isEdit() {
        return !!this.serialId;
      },
      // 对话框标题
      title() {
        return `${this.isEdit ? '编辑' : '新增'}流水`;
      },
      // 签约组件的合作状态枚举值列表，包含已投放(合作中流水)和已完成(后续流水)
      cooperationStatusEnumList() {
        return [
          cooperationStatus.enum.published,
          cooperationStatus.enum.completed,
        ];
      },
      // 支付方式枚举值
      paymentTypeEnum() {
        return paymentType.enum;
      },
      // 流水日期组件的配置选项
      serialDatePickerOptions() {
        const { firstShowTime = '' } = this.sign || {};

        return {
          disabledDate: time =>
            firstShowTime ? format(time) < firstShowTime : false,
        };
      },
    },
    created() {
      this.initModel();
    },
    methods: {
      // 初始化模型
      initModel(data = {}) {
        const {
          signId = '',
          anchorName = '',
          serialDate = '',
          serialType = serialTypes.enum.cooperative,
          gameId = '',
          income = '',
          registration = '',
          expenditureCPT = 0,
          expenditureCPS = 0,
        } = data || {};

        this.model = {
          signId,
          anchorName,
          serialDate,
          serialType,
          gameId,
          income,
          registration,
          expenditureCPT,
          expenditureCPS,
        };
      },
      // 校验模型
      async validateModel() {
        try {
          return await this.$refs.form.validate();
        } catch (e) {
          return false;
        }
      },
      // 重置模型
      resetModel() {
        this.initModel();
        this.$refs.form.resetFields();
      },
      // 保存模型
      async saveModel() {
        const params = {
          ...this.model,
          id: this.serialId,
        };
        const method = this.isEdit ? updateSerial : addSerial;
        const res = await method(params);

        return !!res;
      },
      // 获取流水对象详情
      async getSerial() {
        const res = await getSerial(this.serialId);

        if (!res) return false;

        this.serial = res.data;

        return true;
      },
      // 对话框打开
      async handleDialogOpen() {
        if (!this.isEdit) return;

        this.loading = true;

        const success = await this.getSerial();

        this.loading = false;

        if (!success) return;

        this.sign = this.serial?.sign;
        this.initModel(this.serial);
      },
      // 对话框关闭完成
      handleDialogClosed() {
        this.serial = null;

        this.resetModel();
      },
      // 表单中主播名称(签约对象)改变
      handleFormSignChange(signId, signList) {
        const signObj = signList.find(item => item._id === signId);

        // 缓存签约对象
        this.sign = signObj;

        if (!signObj) return;

        const { firstShowTime, gameId, priceCPS } = signObj;
        const { serialDate, income } = this.model;

        // 当前选中的流水日期早于所选签约的首播日期时，需要重置流水日期
        serialDate < firstShowTime && (this.model.serialDate = '');

        // 自动回填游戏名称
        this.model.gameId = gameId;
        // 计算CPS支出
        this.model.expenditureCPS = Math.round((priceCPS / 100) * income);
      },
      // 表单中流水数输入
      handleFormIncomeInput(val) {
        const { priceCPS = 0 } = this.sign || {};

        this.model.expenditureCPS = Math.round((priceCPS / 100) * val);
      },
      // 对话框取消按钮单击
      handleOperateCancelClick() {
        this.open = false;
      },
      // 对话框确定按钮单击
      async handleOperateOkClick() {
        const isValid = await this.validateModel();

        if (!isValid) return;

        this.saving = true;

        const success = await this.saveModel();

        this.saving = false;

        if (!success) return;

        this.open = false;
        this.$emit('on-ok');
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../../style/mixin.scss';

  .serial-edit {
    .serial-edit-form {
      .el-select,
      .el-input {
        width: 100%;
      }

      // 主播名称
      /deep/.form-anchor {
        .anchor-item {
          @include clearfix();

          width: 420px;
          padding: 5px 0;
          line-height: 25px;
          font-size: 12px;

          .item-container {
            float: left;
            width: 50%;
            padding-right: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .item-label,
            .item-value {
              display: inline-block;
            }

            .item-label {
              width: 60px;
              color: #999;
            }

            &.item-emphasis {
              .item-value {
                font-weight: 700;
              }
            }
          }
        }

        & + .el-select-dropdown__item .anchor-item {
          border-top: 1px dashed #eee;
        }
      }
    }
  }
</style>
