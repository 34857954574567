/**
 * @desc 格式化日期
 * @param {String | Number | Date } date
 * @param {String} format, default 'yyyy-MM-dd'
 * @return {String}
 */
export const format = (date, format = 'yyyy-MM-dd') => {
  if (!date) return '';

  switch (typeof date) {
    case 'string':
      date = new Date(date.replace(/-/g, '/'));
      break;

    case 'number':
      date = new Date(date);
      break;
  }

  if (!(date instanceof Date)) return '';

  const dict = {
    yyyy: date.getFullYear(),
    M: date.getMonth() + 1,
    d: date.getDate(),
    H: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
    MM: ('' + (date.getMonth() + 101)).substring(1),
    dd: ('' + (date.getDate() + 100)).substring(1),
    HH: ('' + (date.getHours() + 100)).substring(1),
    mm: ('' + (date.getMinutes() + 100)).substring(1),
    ss: ('' + (date.getSeconds() + 100)).substring(1),
  };

  return format.replace(/(yyyy|MM?|dd?|HH?|mm?|ss?)/g, function () {
    return dict[arguments[0]];
  });
};

/**
 * @desc 返回指定日期的月历
 * @param {Number | String} date, optional params, default current month
 * @param {Boolean} day2cn, whether translate numerical day to chinese
 * @returns {Array}
 */
export const monthlyCalendar = (date = '', day2cn = true) => {
  const targetDate = date ? new Date(date) : new Date();
  const targetYear = targetDate.getFullYear();
  const targetMonth = targetDate.getMonth();
  const dayMap = ['日', '一', '二', '三', '四', '五', '六'];
  const dateAndDay = [];

  for (let i = 1; i <= 31; i++) {
    targetDate.setDate(i);

    const validDate = targetMonth === targetDate.getMonth();

    if (!validDate) break;

    const day = targetDate.getDay();

    dateAndDay.push({
      year: targetYear,
      month: targetMonth + 1,
      date: i,
      day: day2cn ? dayMap[day] : day,
      dayNumber: day || 7,
    });
  }

  return dateAndDay;
};

/**
 * @description 返回开始时间和结束时间区间内(包含开始和结束时间)的日期数组
 * @param {String | Number | Date} startTime 开始时间
 * @param {String | Number | Date} endTime 结束时间
 * @returns {Array<Date>} 开始时间(包含)到结束时间(包含)之间的日期数组
 */
export const getDatesFromDateRange = (startTime, endTime) => {
  const dayMS = 24 * 3600 * 1000;
  const startDay = new Date(startTime).setHours(0, 0, 0, 0);
  const endDay = new Date(endTime).setHours(0, 0, 0, 0);
  const dayRange = (endDay - startDay) / dayMS;
  const dates = [];

  for (let i = 0; i <= dayRange; i++) {
    const date = new Date(startDay + i * dayMS);

    dates.push(date);
  }

  return dates;
};
