<!-- sign -->
<template>
  <el-select
    class="sign"
    v-model="selected"
    :placeholder="placeholder"
    :disabled="disabled"
    :clearable="clearable"
    :filterable="filterable"
    :filter-method="filterMethod"
    :default-first-option="defaultFirstOption"
    :size="size"
    remote
    :remote-method="getSignsFuzzy"
    :loading="loading"
    :popper-append-to-body="false"
    :debounce="debounce"
    @change="handleValueChange"
    @clear="handleValueClear"
  >
    <el-option
      v-for="(item, index) in list"
      :label="item[labelKey]"
      :value="item[valueKey]"
      :key="item[valueKey]"
    >
      <slot v-if="$scopedSlots.default" :item="item" :index="index" />

      <div v-else class="option-item">
        <div class="item-container">
          <span class="item-label">主播名称:</span>
          <span class="item-value">{{ item.anchorName }}</span>
        </div>

        <div class="item-container">
          <span class="item-label">价位:</span>
          <span class="item-value">{{ item | priceText }}</span>
        </div>

        <div class="item-container">
          <span class="item-label">游戏名称:</span>
          <span class="item-value">{{ item.gameName }}</span>
        </div>

        <div class="item-container">
          <span class="item-label">预算:</span>
          <span class="item-value">{{ `${item.budget}元` }}</span>
        </div>

        <div class="item-container">
          <span class="item-label">商务名称:</span>
          <span class="item-value">{{ item.username }}</span>
        </div>
      </div>
    </el-option>
  </el-select>
</template>

<script>
  import select from '../../mixins/select';
  import cooperationStatus from '../../enum/cooperation-status';
  import paymentType from '../../enum/payment-type.js';
  import { getSignsSelect } from '../../api/common';

  export default {
    name: 'sign',
    mixins: [select],
    props: {
      // 搜索防抖时长，单位: ms，默认: 500
      debounce: {
        type: Number,
        default: 500,
      },
      // 合作状态，默认: 全部，可选: '已签约'、'已排期'、'已投放'、'已完成'
      status: {
        type: [Number, Array],
        validator: val => {
          const statusArr = Array.isArray(val) ? val : [val];
          const statusMap = cooperationStatus.map;

          return !statusArr.some(status => !statusMap.hasOwnProperty(status));
        },
        default: () => Object.values(cooperationStatus.enum),
      },
      // 限制个数，默认: 5
      limit: {
        type: Number,
        default: 5,
      },
      // 是否开启数据权限
      enableDataPermission: {
        type: Boolean,
        default: false,
      },
    },
    filters: {
      // 价位文案
      priceText(val) {
        const { payment, priceCPT, priceCPS } = val;
        let paymentPriceText = '';

        switch (payment) {
          case paymentType.enum.cps:
            paymentPriceText = `${priceCPS}%`;
            break;

          case paymentType.enum.cptAndCps:
            paymentPriceText = `${priceCPT}元/天 + ${priceCPS}%`;
            break;

          default:
            paymentPriceText = `${priceCPT}元/天`;
        }

        return paymentPriceText;
      },
      // 支付方式文案
      paymentTypeText(val) {
        return paymentType.map[val] || '';
      },
    },
    data() {
      return {
        loading: false,
      };
    },
    mounted() {
      this.getSignsFuzzy();
    },
    methods: {
      async getSignsFuzzy(keyword) {
        const status = this.status;
        const params = {
          status: Array.isArray(status) ? status.join() : status,
          keyword,
          limit: this.limit,
          enableDataPermission: this.enableDataPermission,
        };

        this.loading = true;

        const res = await getSignsSelect(params);

        this.loading = false;

        if (!res) {
          this.list = [];

          return;
        }

        const { data: signs = [] } = res;

        this.list = signs.map(item => {
          const { _id, anchorName } = item;

          return {
            ...item,
            [this.labelKey]: anchorName,
            [this.valueKey]: _id,
          };
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../style/mixin.scss';

  .sign {
    /deep/ .el-select-dropdown .el-select-dropdown__item {
      height: auto;

      .option-item {
        @include clearfix();

        width: 420px;
        padding: 5px 0;
        line-height: 25px;
        font-size: 12px;

        .item-container {
          float: left;
          width: 50%;
          padding-right: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .item-label,
          .item-value {
            display: inline-block;
          }

          .item-label {
            width: 60px;
            color: #999;
          }

          &:nth-of-type(2n) {
            .item-label {
              width: 36px;
            }
          }
        }
      }

      & + .el-select-dropdown__item .option-item {
        border-top: 1px dashed #eee;
      }
    }
  }
</style>
