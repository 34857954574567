// 支付方式
export default {
  map: {
    1: 'CPT',
    2: 'CPS',
    3: 'CPT + CPS',
  },
  enum: {
    cpt: 1,
    cps: 2,
    cptAndCps: 3,
  },
};
